import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject } from '@angular/core';
import { PasswordInputComponent } from '../../../shared/widgets/inputs/password-input/password-input.component';
import { GeneralInputComponent } from '../../../shared/widgets/inputs/general-input/general-input.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';
import { AppState } from '../../../core/store';
import { Store } from '@ngrx/store';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import {
  clearToastAction,
  errorToastAction,
  loadingToastAction,
} from '../../../core/store/actions/toast.actions';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { GeneralButtonDirective } from '../../../shared/directives/buttons/general-button.directive';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-mda-login',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    PasswordInputComponent,
    GeneralInputComponent,
    ReactiveFormsModule,
    RouterModule,
    GeneralButtonDirective,
    SvgIconComponent,
  ],
  templateUrl: './mda-login.component.html',
  styleUrl: './mda-login.component.css',
})
export class MdaLoginComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  store = inject(Store<AppState>);
  routeTo = injectQueryParams('routeTo');

  //Form control
  authForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  });

  // API call (Login)
  mutation = injectMutation(() => ({
    mutationFn: (loginData: { [key: string]: any }) =>
      this.authService.login(loginData),
    onSuccess: ({ id, ...others }) => {
      this.store.dispatch(clearToastAction());

      const role = (others?.roles || [])?.[0];

      if ('MDA User' === role) {
        this.authForm.reset();
        this.authService.setUserData(others);
      }

      switch (role) {
        case 'Governor':
          this.router.navigate(['/governor']);
          break;
        case 'SuperAdmin':
          this.router.navigate(['/superadmin']);
          break;
        case 'Admin':
        case 'Administrator':
          this.router.navigate(['/admin-auth']);
          break;
        case 'Expert System':
          this.router.navigate(['/expert-system']);
          break;
        case 'MDA User':
          this.router.navigate(['/mda']);
          break;

        default:
          this.router.navigate(['/']);
          break;
      }
      //OR use the queryClient that is injected into the component
      //this.queryClient.invalidateQueries({ queryKey: ['todos'] })
    },
    onError: (error: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while logging in. Try again shortly',
        })
      );
    },
  }));

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Logging in. Please wait...',
      })
    );
    this.mutation.mutate(this.authForm.value);
  }
}
