import { Component, computed, inject, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { BillsService } from '../../../core/services/bills.service';
import { LocalService } from '../../../core/services/local.service';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { ReceiptData } from '../../../shared/models';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import dayjs from 'dayjs';
import { CommonModule } from '@angular/common';
import { ReceiptComponent } from '../../user-protected/tax-payment/receipt/receipt.component';

@Component({
    standalone: true,
  selector: 'app-payment-summary-open',
  imports: [CommonModule, ReceiptComponent, RouterModule],
  templateUrl: './payment-summary-open.component.html',
  styleUrl: './payment-summary-open.component.css',
})
export class PaymentSummaryOpenComponent {
  router = inject(Router);
  billService = inject(BillsService);
  localService = inject(LocalService);
  redirectQueryparams = injectQueryParams('redirect');
  isAnonymous = injectQueryParams('isAnonymous');
  isMdaUser = injectQueryParams('isMdaUser');
  isAReceipt = computed(() => {
    return this.billQueryResponse.data()?.bill?.payStatusId ?? 1;
  });

  @Input() amount;
  @Input() status;

  ref = injectQueryParams('ref');
  billReference = injectQueryParams('billReference');

  billQueryResponse = injectQuery(() => {
    return {
      queryKey: ['single-bill', this.ref(), this.billReference()],
      queryFn: ({ queryKey }) => {
        const [, ref, billRef] = queryKey;
        return this.billService.getBill({
          billReference: ref || billRef,
        });
      },
      enabled: !!this.ref() || !!this.billReference(),
      retry: 1,
    };
  });

  isLoading = computed(() => {
    return (
      this.billQueryResponse.fetchStatus() === 'fetching' &&
      this.billQueryResponse.isPending()
    );
  });

  receiptData = computed<ReceiptData>(() => {
    const bill = this.billQueryResponse.data() as ObjectLiteral;
    const userData = this.billQueryResponse.data()?.userData?.user;
    const userAddress =
      this.billQueryResponse.data()?.userData?.userRegistration?.addressLine1;
    return {
      ...bill,
      billTo: {
        name: `${userData?.firstName} ${userData?.lastName}`,
        address: userAddress || '',
        email: `${userData?.email}`,
      },
      billFrom: {
        name: 'Kaduna State Internal Revenue Service',
        address: 'Kaduna State',
      },
      items: (bill?.bill?.billDetails || []).map((item) => ({
        amount: item?.amount || 0,
        rate: item?.amount || 0,
        description: item?.narration,
        quantity: 1,
      })),
      totalAmount:
        (bill?.bill?.billDetails || []).reduce((a, b) => a + b.amount, 0) || 0,
      userData: this.billQueryResponse.data()?.userData || {},
    } as ReceiptData;
  });

  get issuanceDate() {
    return dayjs().format('MMM DD, YYYY');
  }
  get expiryDate() {
    return dayjs().add(3, 'months').format('MMM DD, YYYY');
  }
}
