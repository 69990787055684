<div [ngClass]="{ 'w-full h-dvh overflow-auto flex flex-col': true }">
  <div [ngClass]="{ 'py-6 w-full max-w-[1000px] mx-auto': true }">
    <div class="flex items-center pl-[13px]">
      <a
        class="flex items-center font-medium text-gray-900 title-font md:mb-0"
        routerLink="/user/dashboard"
        nz-tooltip
        nzTooltipPlacement="right"
        nzTooltipTitle="Home"
      >
        <img
          [src]="'assets/images/logo_with_name.png'"
          class="w-[130px] rounded-full overflow-hidden object-fill"
        />
      </a>
    </div>
  </div>

  <app-receipt
    [receiptData]="receiptData()"
    [isLoading]="isLoading()"
    [isInvoice]="isAReceipt() === 1 ? true : false"
  ></app-receipt>
</div>
