import { CommonModule, Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { InvoicePaymentPageTspComponent } from '../../invoice-payment-page-tsp/invoice-payment-page-tsp.component';
import { Router } from '@angular/router';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';

@Component({
  selector: 'app-payment-gateways-tsp',
  standalone: true,
  imports: [CommonModule, SvgIconComponent, InvoicePaymentPageTspComponent],
  templateUrl: './payment-gateways-tsp.component.html',
  styleUrl: './payment-gateways-tsp.component.css',
})
export class PaymentGatewaysTspComponent {
  locationService = inject(Location);
  router = inject(Router);
  tpui: string;

  goBack() {
    this.locationService.back();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const state: ObjectLiteral = this.locationService.getState();
    console.log(state); // { id: 123, name: 'John' }
    this.tpui = state?.tpui || '';
  }
}
