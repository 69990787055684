<header
  [ngClass]="{
    'body-font py-2 flex items-center': true
  }"
>
  <button
    nz-button
    nzShape="round"
    nzType="link"
    (click)="toggleSideMenuCollapsedState()"
  >
    <svg-icon
      src="assets/svgs/menu.svg"
      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
    ></svg-icon>
  </button>

  <div [ngClass]="{ 'flex items-center justify-end gap-6 flex-1': true }">
    <p
      class="font-semibold text-primary-500 text-[1rem]"
      [ngStyle]="{ 'line-height': 1 }"
    >
      {{ todayDate }}
    </p>

    <a
      class="flex items-center font-medium text-gray-900 title-font md:mb-0 bg-[#F7F7F7] p-3 rounded-full"
      [routerLink]=""
      routerLinkActive="bg-slate-200"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <svg-icon
        src="assets/svgs/notification-icon.svg"
        [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
      ></svg-icon>
    </a>

    <span>
      <a
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="menu"
        class="flex items-center gap-2"
      >
        <nz-avatar
          [nzSize]="'large'"
          [nzGap]="4"
          [nzText]="abbr"
          [ngClass]="{ 'bg-tw-primary': true }"
          [nzSrc]="userData?.avatar"
        ></nz-avatar>

        <span class="flex flex-col gap-0">
          <p class="text-xs font-semibold text-left capitalize">
            {{ userData?.firstName }}&nbsp;
            {{ userData?.lastName }}
          </p>
          <p class="text-xs text-left capitalize">
            {{ userRole }}
          </p>
        </span>

        <svg-icon
          src="assets/svgs/chevron-up.svg"
          [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
        ></svg-icon>
      </a>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="w-[150px]">
          @for (item of menuList(); track $index) {
          <li
            nz-menu-item
            [nzDanger]="item.type === 'Danger' ? true : false"
            (click)="logoutHandler(item.name, item.link)"
            role="button"
          >
            {{ item.name }}
          </li>
          }
        </ul>
      </nz-dropdown-menu>
    </span>
  </div>
</header>

<!-- *ngIf="!!authService.userData&&!hideRoutes -->
