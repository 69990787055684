import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilKeyChanged,
  finalize,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { RoleService } from '../services/role.service';
import {
  assignRoleAction,
  createRoleAction,
  deleteRoleAction,
  resetListQueryAction,
  setDocAction,
  setListAction,
  setListQueryAction,
  setSearchAction,
  setStateAction,
  unassignRoleAction,
} from '../store/actions/role.actions';
import { getRoleStore } from '../store/selectors/role.selectors';
import { errorToastAction } from '../store/actions/toast.actions';

@Injectable()
export class RoleEffects {
  constructor(
    private actions$: Actions,
    private roleService: RoleService,
    private store: Store
  ) {}

  loadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListAction),
      withLatestFrom(this.store.pipe(select(getRoleStore))),
      switchMap(([payload, roleStore]) => {
        // set listLoading to true
        this.store.dispatch(setStateAction({ listLoading: true }));

        return this.roleService
          .getAllRoles({
            pageNumber: roleStore.page,
            pageSize: roleStore.size,
            criteria: roleStore.search,
            sortField: roleStore.sortField,
            sortOrder: roleStore.sortOrder,
          })
          .pipe(
            map((response) =>
              setStateAction({
                total: response.totalRecords,
                list: response.data.map((item) => ({ name: item.name })),
              })
            ),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while fetching roles data',
                })
              )
            ),
            finalize(() =>
              this.store.dispatch(
                setStateAction({
                  listLoading: false,
                })
              )
            )
          );
      })
    )
  );

  setQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setListQueryAction),
      map((payload) => this.store.dispatch(setStateAction({ ...payload }))),
      map(() => setListAction(null))
    )
  );

  resetQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetListQueryAction),
      map((payload) =>
        this.store.dispatch(
          setStateAction({
            page: 1,
            sortOrder: '',
            sortField: '',
            search: '',
          })
        )
      ),
      map(() => setListAction(null))
    )
  );

  setSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSearchAction),
      map((payload) => {
        this.store.dispatch(
          setStateAction({ search: payload.keyword, page: 1 })
        );
        return payload;
      }),
      debounceTime(300),
      distinctUntilKeyChanged('keyword'),
      map(() => {
        return setListAction(null);
      })
    )
  );

  loadDoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDocAction),
      switchMap(({ name }) => {
        // set docLoading to true
        this.store.dispatch(setStateAction({ docLoading: true, doc: null }));

        return this.roleService.getRole({ roleName: name }).pipe(
          map((response) =>
            setStateAction({
              doc: response,
            })
          ),
          catchError((error) =>
            of(
              errorToastAction({
                autohide: true,
                delay: 4000,
                placement: 'top-end',
                message:
                  (error as any)?.error?.message ||
                  'An error occurred while fetching role data',
              })
            )
          ),
          finalize(() =>
            this.store.dispatch(setStateAction({ docLoading: false }))
          )
        );
      })
    )
  );

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteRoleAction),
      switchMap(({ name, setLoading, onSuccess }) => {
        if (setLoading) setLoading(true);

        return this.roleService
          .deleteRoles({
            roleNames: [name],
          })
          .pipe(
            map((response) => {
              if (onSuccess) onSuccess();
              return response;
            }),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while deleting roles',
                })
              )
            ),
            finalize(() => {
              if (setLoading) setLoading(false);
            })
          );
      })
    )
  );

  createRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createRoleAction),
      switchMap(({ name, setLoading, onSuccess }) => {
        if (setLoading) setLoading(true);

        return this.roleService
          .createRoles({
            roleNames: [name],
          })
          .pipe(
            map((response) => {
              if (onSuccess) onSuccess();
              return response;
            }),
            catchError((error) =>
              of(
                errorToastAction({
                  autohide: true,
                  delay: 4000,
                  placement: 'top-end',
                  message:
                    (error as any)?.error?.message ||
                    'An error occurred while creating role',
                })
              )
            ),
            finalize(() => {
              if (setLoading) setLoading(false);
            })
          );
      })
    )
  );

  assignRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(assignRoleAction),
        switchMap(({ userIds, role, setLoading, onSuccess }) => {
          if (setLoading) setLoading(true);

          return this.roleService
            .assignRolesToUsers({
              roleNames: [role],
              userIds,
            })
            .pipe(
              mergeMap((response) => {
                if (onSuccess) onSuccess();
                return response;
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while assigning role',
                  })
                )
              ),
              finalize(() => {
                if (setLoading) setLoading(false);
              })
            );
        })
      ),
    { dispatch: false }
  );

  unassignRole$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(unassignRoleAction),
        switchMap(({ userIds, role, setLoading, onSuccess }) => {
          if (setLoading) setLoading(true);

          return this.roleService
            .unassignRolesFromUsers({
              roleNames: [role],
              userIds,
            })
            .pipe(
              mergeMap((response) => {
                if (onSuccess) onSuccess();
                return response;
              }),
              catchError((error) =>
                of(
                  errorToastAction({
                    autohide: true,
                    delay: 4000,
                    placement: 'top-end',
                    message:
                      (error as any)?.error?.message ||
                      'An error occurred while unassigning role',
                  })
                )
              ),
              finalize(() => {
                if (setLoading) setLoading(false);
              })
            );
        })
      ),
    { dispatch: false }
  );
}
