<div [ngClass]="{ 'w-full bg-img flex flex-col flex-1': true }">
  <div [ngClass]="{ '!hidden': !!comingSoon, 'flex-1': true }">
    <app-header></app-header>
    <app-public-side-drawer></app-public-side-drawer>
    <router-outlet></router-outlet>
    <div
      [ngClass]="{
        'w-full flex text-white justify-center items-center gap-4 mb-32': true
      }"
    >
      <p>&#169;&nbsp;copyright 2025</p>
      <div [ngClass]="{ 'flex items-center gap-2': true }">
        <img
          ngSrc="assets/images/receipt/ibs_logo.png"
          class="h-[30px] w-[50px] overflow-hidden object-fill"
          priority
          width="50"
          height="30"
        />
        <img
          [ngSrc]="'assets/images/ndpc.png'"
          alt="ndpc-logo"
          priority
          width="60"
          height="30"
          [ngClass]="{ 'h-[30px] w-[60px]': true }"
        />

        <img
          [ngSrc]="'assets/images/pcidss.png'"
          alt="pcidss-logo"
          priority
          width="60"
          height="30"
          [ngClass]="{ 'h-[30px] w-[60px]': true }"
        />
      </div>
    </div>
  </div>
  @if (comingSoon) {
  <ng-container *ngTemplateOutlet="coming_soon"></ng-container>
  }
</div>

<ng-template #coming_soon>
  <app-coming-soon></app-coming-soon>
</ng-template>
