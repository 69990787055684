import { createReducer, on } from '@ngrx/store';
import { setListAction, setStateAction } from '../actions/user.actions';
import { Action } from '../../models';

export interface User {
  firstName: string;
  middleName: string;
  lastName: string;
  createdAt: string;
  modifiedAt: string;
  registration: any;
  id: string;
  userId: string;
  userName: string;
  normalizedUserName: string;
  email: string;
  normalizedEmail: string;
  emailConfirmed: boolean;
  passwordHash: string;
  securityStamp: string;
  concurrencyStamp: string;
  phoneNumber: string;
  phoneNumberConfirmed: boolean;
  twoFactorEnabled: boolean;
  lockoutEnd: any;
  lockoutEnabled: boolean;
  accessFailedCount: number;
  roleName: string;
  dob: string;
  dateOfBirth: string;
}

export interface UserReducerInitialState {
  page: number;
  total: number;
  size: number;
  sortOrder: string;
  sortField: string;
  search: string;
  listLoading: boolean;
  list: User[] | null;
  docLoading: boolean;
  doc: User | null;
}

export const initialState: UserReducerInitialState = {
  page: 1,
  total: 0,
  size: 50,
  sortOrder: '',
  sortField: '',
  search: '',
  listLoading: false,
  list: null,
  docLoading: false,
  doc: null,
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function userReducer(state: UserReducerInitialState, action: Action) {
  return reducer(state, action);
}
