import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { PasswordInputComponent } from '../../inputs/password-input/password-input.component';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { AuthService } from '../../../../core/services/auth.service';
import {
  clearToastAction,
  errorToastAction,
  loadingToastAction,
} from '../../../../core/store/actions/toast.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/store';
import { GeneralInputComponent } from '../../inputs/general-input/general-input.component';
import { GeneralInputDirective } from '../../../directives/inputs/general-input.directive';
import { GeneralButtonDirective } from '../../../directives/buttons/general-button.directive';
import { setStateAction } from '../../../../core/store/actions/invoice.actions';
import { injectQueryParams } from 'ngxtension/inject-query-params';

@Component({
  selector: 'app-login-ui',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    ReactiveFormsModule,
    PasswordInputComponent,
    RouterModule,
    GeneralInputComponent,
    GeneralButtonDirective,
  ],
  templateUrl: './login-ui.component.html',
  styleUrl: './login-ui.component.css',
})
export class LoginUiComponent {
  //Services
  router = inject(Router);
  route = inject(ActivatedRoute);
  authService = inject(AuthService);
  store = inject(Store<AppState>);
  routeTo = injectQueryParams('routeTo');

  //Form control
  authForm = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, [Validators.required]),
  });

  // API call (Login)
  mutation = injectMutation(() => ({
    mutationFn: (loginData: { [key: string]: any }) =>
      this.authService.login(loginData),
    onSuccess: ({ id, ...others }) => {
      // Invalidate and refetch by using the client directly

      this.store.dispatch(clearToastAction());
      if (this.routeTo() === 'mda-collections') {
        this.router.navigate([''], {
          queryParams: null,
        });
        this.store.dispatch(setStateAction({ modalState: true }));
        return;
      }
      const role = (others?.roles || [])?.[0];

      if (
        [
          'Agent',
          'Individual',
          'Corporate',
          'State Agency',
          'Federal Agency',
        ].includes(role)
      ) {
        this.authForm.reset();
        this.authService.setUserData(others);
      }

      switch (role) {
        case 'Governor':
          this.router.navigate(['/governor']);
          break;
        case 'SuperAdmin': {
          this.router.navigate(['/superadmin']);
          break;
        }
        // this.router.navigate(['/sadmin']);
        // break;
        case 'Admin':
        case 'Administrator':
          this.router.navigate(['/admin-auth']);
          break;
        case 'Agent':
        case 'Individual':
        case 'Corporate':
        case 'State Agency':
        case 'Federal Agency':
          this.router.navigate(['/user/dashboard']);
          break;
        case 'Expert System':
          this.router.navigate(['/tsp']);
          break;
        case 'MDA User':
          this.router.navigate(['/MDAmanager']);
          break;

        default:
          this.router.navigate(['/']);
          break;
      }
      //OR use the queryClient that is injected into the component
      //this.queryClient.invalidateQueries({ queryKey: ['todos'] })
    },
    onError: (error: any) => {
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while logging in. Try again shortly',
        })
      );
    },
  }));

  onSubmit() {
    if (!this.authForm.valid) {
      this.authForm.markAllAsTouched();
      return;
    }
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Logging in. Please wait...',
      })
    );
    this.mutation.mutate(this.authForm.value);
  }

  goToRegistrationForm(id: string | number) {
    this.router.navigate([`/auth/register/${id}`]);
  }
  switchToRegister() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'register',
      },
    });
  }
  goBack() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }
}
