<div>
  <div
    [ngClass]="{
      'w-full max-w-[1000px] mx-auto flex justify-between pt-16 pb-6': true
    }"
  >
    <div class="flex items-center pl-[13px]">
      <a
        class="flex items-center font-medium text-gray-900 title-font md:mb-0"
        routerLink="/"
        nz-tooltip
        nzTooltipPlacement="right"
        nzTooltipTitle="Home"
      >
        <img
          [src]="'assets/images/logo_with_name.png'"
          class="w-[200px] rounded-full overflow-hidden object-fill"
        />
      </a>
    </div>

    <div [ngClass]="{ 'flex w-full justify-end': true }">
      <button
        [ngClass]="{
          'flex items-center text-base font-semibold': true
        }"
        (click)="goBack()"
      >
        <svg-icon
          src="assets/svgs/cancel.svg"
          [svgStyle]="{ 'width.px': 24 }"
        ></svg-icon>
        <span>Close</span>
      </button>
    </div>
  </div>
  <div [ngClass]="{ 'max-w-[700px] mx-auto pb-16': true }">
    <div>
      <ng-container [ngTemplateOutlet]="paymentStep"></ng-container>
    </div>
  </div>
</div>

<ng-template #paymentStep>
  <app-invoice-payment-page-tsp
    [isMdaUser]="false"
    [tpui]="tpui"
  ></app-invoice-payment-page-tsp>
</ng-template>
