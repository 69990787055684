import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ScriptLoaderService } from '../../core/services/script-loader.service';
import { AuthService } from '../../core/services/auth.service';
import { PaymentService } from '../../core/services/payment.service';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { injectMutation } from '@tanstack/angular-query-experimental';
import { ObjectLiteral } from '../../core/models/ObjectLiteral';
import {
  errorToastAction,
  loadingToastAction,
  successToastAction,
} from '../../core/store/actions/toast.actions';
import { environment } from '../../../environments/environment';
import dayjs from 'dayjs';
import {
  gatewayNotes,
  GatewayProps,
  gateways,
} from '../../shared/invoice-modal/invoice-payment-page/invoice-payment-page.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { GeneralButtonDirective } from '../../shared/directives/buttons/general-button.directive';
import { InterswitchMenuComponent } from '../../shared/invoice-modal/invoice-payment-page/interswitch-menu/interswitch-menu.component';

@Component({
  selector: 'app-invoice-payment-page-tsp',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    GeneralButtonDirective,
    InterswitchMenuComponent,
  ],
  templateUrl: './invoice-payment-page-tsp.component.html',
  styleUrl: './invoice-payment-page-tsp.component.css',
})
export class InvoicePaymentPageTspComponent {
  store = inject(Store<AppState>);
  router = inject(Router);
  route = inject(ActivatedRoute);
  scriptLoader = inject(ScriptLoaderService);
  authService = inject(AuthService);
  paymentService = inject(PaymentService);
  isAnonymous = injectQueryParams('isAnonymous');
  isMdaUserQuery = injectQueryParams('isMdaUser');
  isInterSwitch = signal<boolean>(false);
  isFromTsp = injectQueryParams('isFromTsp');

  pathname: string;
  gatewayList = gateways;
  noteList = gatewayNotes;
  currentTab = 'remita';
  ref = signal('');

  @Input() isMdaUser = false;
  @Input() tpui = '';
  @Output() onStepChange: EventEmitter<number> = new EventEmitter();
  selectedGateway: GatewayProps = this.gatewayList.find(
    (gateway) => gateway.slug === this.currentTab
  );

  paymentLinkMutation = injectMutation(() => ({
    mutationFn: (data: ObjectLiteral) =>
      this.paymentService.generateTSPPaymentLink(data),
    onSuccess: (data: any) => {
      this.store.dispatch(
        successToastAction({
          autohide: false,
          delay: 7000,
          placement: 'top-end',
          message:
            'Payment session created successfully. You will be redirected shortly',
        })
      );
      console.log('data :>> ', data);
      const url = new URL(data?.checkoutUrl);
      const searchParams = url.searchParams;
      const rrr = searchParams.get('rrr');

      const paymentDataWithRrr = {
        key: environment.remita_pub_key,
        processRrr: true,
        transactionId: `${this.ref()}_${+dayjs()}`, //you are expected to generate new values for the transactionId for each transaction processing.
        channel:
          'CARD,USSD,TRANSFER,PAYWITHREMITA,PHONENUMBER,WALLET,ENAIRA,BANK,BRANCH,IBANK', //this field is used to filter what card channels you want enabled on the payment modal
        extendedData: {
          customFields: [
            {
              name: 'rrr',
              value: rrr, //rrr to be processed.
            },
          ],
        },
        onSuccess: (response) => {
          console.log('callback Successful Response', response);
          if (!!data?.redirectUrl) {
            sessionStorage.setItem('redirect-url', data.redirectUrl);
          }
          this.router.navigate(['/payment-redirect-open'], {
            queryParams: {
              billRef: this.ref(),
              provider: 'remita',
            },
          });
        },
        onError: (response) => {
          console.log('callback Error Response', response);
        },
        onClose: function () {
          console.log('closed');
        },
      };

      const handler =
        this.scriptLoader.RmPaymentEngine.init(paymentDataWithRrr);
      handler.showPaymentWidget();
    },
    onError: (error: any) => {
      console.log('error :>> ', error);
      this.store.dispatch(
        errorToastAction({
          autohide: true,
          delay: 7000,
          placement: 'top-end',
          message:
            error?.error?.message ||
            'An error occurred while creating a payment session',
        })
      );
    },
  }));

  handleCardClick(gateway: GatewayProps) {
    if (gateway.slug === 'interswitch') {
      this.isInterSwitch.set(true);
    }
    this.currentTab = gateway.slug;
    this.selectedGateway = gateway;
  }

  viewInvoiceHandler() {
    this.router.navigate(['/make_payment_tsp'], {
      queryParamsHandling: 'merge',
    });
  }

  generatePaymentLink() {
    this.store.dispatch(
      loadingToastAction({
        autohide: false,
        delay: 7000,
        placement: 'top-end',
        message: 'Creating payment session. Please wait...',
      })
    );
    this.paymentLinkMutation.mutate({
      billReference: this.ref(),
      provider: this.currentTab,
      tpui: this.tpui,
    });
  }

  closeInterSwitch() {
    this.currentTab = 'remita';
    this.selectedGateway = this.gatewayList.find(
      (gateway) => gateway.slug === this.currentTab
    );
    this.isInterSwitch.set(false);
  }

  ngOnInit(): void {
    // Accessing query parameters from the root
    this.route.queryParams.subscribe((params) => {
      this.ref.set(params['billReference'] || params['ref']);
    });
  }

  constructor() {
    this.pathname = this.router.url.split('?')[0];
  }
}
