<div class="min-w-[850px] mx-auto min-h-[526px] bg-white rounded-[20px] p-8">
  <div class="relative">
    <button
      type="button"
      (click)="goBack()"
      [ngClass]="{ 'flex items-center gap-2 text-tw-text-color': true }"
    >
      <svg-icon
        src="assets/svgs/back_arrow_icon.svg"
        [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"
      ></svg-icon>
      <span class="text-sm font-medium">Go back</span>
    </button>
  </div>
  <!-- Title Section -->
  <div class="w-full">
    <div class="relative flex justify-center w-full pb-8 text-center">
      <img
        [src]="'assets/images/icon-logo.png'"
        class="w-[50px] overflow-hidden object-fill"
      />
    </div>

    <div [ngClass]="{ 'flex flex-col items-center pb-8': true }">
      <h3 [ngClass]="{ 'text-[#292929] text-2xl font-bold': true }">
        Register
      </h3>
      <p [ngClass]="{ 'text-tw-text-color text-base': true }">
        Select preferred category
      </p>
    </div>
  </div>

  <div [ngClass]="{ 'flex gap-4 px-4': true }">
    @for (accountType of accounTypeList; track accountType.id) {
    <div
      [ngClass]="{
        'py-5 px-6 flex-1 rounded-2xl shadow-[40px_-20px_100px_40px_rgba(206,204,204,0.25)] min-w-0': true
      }"
    >
      <div
        class="svgContainer w-[67px] h-[67px] mx-auto flex items-center justify-center rounded-full"
        [ngStyle]="{ 'background-color': accountType.color }"
      >
        <svg-icon
          [src]="accountType.iconPath"
          [svgStyle]="{ 'width.px': 34, 'height.px': 34 }"
        ></svg-icon>
      </div>
      <h5
        [ngClass]="{
          'text-[#292929] text-lg font-bold text-center py-2': true
        }"
      >
        {{ accountType.text }}
      </h5>
      <div [ngClass]="{ 'text-center text-tw-text-color text-sm pb-5': true }">
        {{ accountType.description }}
      </div>
      <div [ngClass]="{ 'w-full': true }">
        <button
          [ngClass]="{
            'text-tw-text-color text-sm font-semibold py-3 w-full rounded-lg border border-[#DCDCDC]': true
          }"
          (click)="goToRegistrationForm(accountType.id)"
        >
          Select
        </button>
      </div>
    </div>
    }
  </div>
  <div
    class="flex items-center justify-center gap-2 py-8 text-center login-notice"
  >
    <p
      [ngClass]="{
        'text-tw-text-color font-normal text-base capitalize': true
      }"
    >
      Already have an account?
    </p>
    <button
      [ngClass]="{ 'text-[#226449] text-base underline font-bold': true }"
      (click)="switchToLogin()"
    >
      Sign in
    </button>
  </div>
</div>
