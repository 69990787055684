import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import {
  Component,
  EnvironmentInjector,
  inject,
  runInInjectionContext,
  signal,
} from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SignalRService } from '../../../core/services/signal-r.service';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { AuthService } from '../../../core/services/auth.service';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { CountdownNoformattingService } from '../../../core/services/countdown-noformatting.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    standalone: true,
  selector: 'app-payment-redirect-open',
  imports: [NzModalModule, CommonModule, NgOptimizedImage, NzProgressModule],
  templateUrl: './payment-redirect-open.component.html',
  styleUrl: './payment-redirect-open.component.css',
})
export class PaymentRedirectOpenComponent {
  billRef = injectQueryParams('billRef');
  signalRService = inject(SignalRService);
  signalSubscription: Subscription;
  countDownSubscription: Subscription;
  router = inject(Router);
  location = inject(Location);
  isConnected = signal<boolean>(false);
  socketResponse = signal<ObjectLiteral | null>(null);
  //   countDownService = inject(CountdownService);
  countDownNoFormattingService = inject(CountdownNoformattingService);
  private envInjector = inject(EnvironmentInjector);
  isBrowser: boolean;
  redirectUrl: string = '';
  authService = inject(AuthService);
  isSuccess = signal<boolean>(false);
  formatOne = (percent: number): string => {
    const val = percent / 10;
    return `${val} ${val <= 1 ? 'sec' : 'secs'}`;
  };

  modalState = true;

  onCancel(): void {
    this.modalState = false;
  }

  get parseInteger() {
    return parseInt;
  }

  get getAnonymityStatus() {
    return this.router.url.includes('/payment-redirect-open');
  }

  constructor() {
    runInInjectionContext(this.envInjector, () => {
      this.isBrowser = typeof window !== 'undefined';
    });
  }

  ngOnInit(): void {
    if (!!this.isBrowser) {
      this.redirectUrl = sessionStorage.getItem('redirect-url') || '';
    }

    this.countDownSubscription = this.countDownNoFormattingService
      .watchCountDown()
      .subscribe((data: boolean) => {
        if (data && !!this.isSuccess()) {
          const receiptRoute = '/payment_summary_open';

          this.router.navigate([receiptRoute], {
            queryParams: {
              ref: this.billRef(),
            },
          });
          return;
        }
        if (
          data &&
          !this.isSuccess() &&
          !!this.isConnected() &&
          !!this.socketResponse()
        ) {
          const userData = this.authService.userData();

          this.signalRService.sendMessage('PaymentNotificationRequest', {
            userId: userData?.userId,
            billReference: this.billRef(),
          });

          this.countDownNoFormattingService.startCountdown(20);
        }
      });

    this.signalRService.startConnectionAnonymous();

    //PaymentNotificationResponse
    this.signalRService.addMessageListener(
      'PaymentNotificationResponse',
      (data: ObjectLiteral) => {
        this.socketResponse.set(data);
        if (![400, 200].includes(data.statusCode)) {
          this.isSuccess.set(false);
          return;
        }
        this.countDownNoFormattingService.startCountdown(10);
        this.isSuccess.set(true);
        return;
      }
    );

    this.signalSubscription = this.signalRService
      .watchConnection()
      .subscribe((data) => {
        this.isConnected.set(data);

        if (!!data && !!this.billRef()) {
          const userData = this.authService.userData();

          this.signalRService.sendMessage('PaymentNotificationRequest', {
            userId: userData?.userId,
            billReference: this.billRef(),
          });
        }
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.countDownSubscription) {
      this.countDownSubscription.unsubscribe();
    }
    if (this.signalSubscription) {
      this.signalSubscription.unsubscribe();
    }
  }
}
