// import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { AuthComponent } from '../pages/auth/auth.component';
import {
  authAdminGuard,
  authSuperAdminGuard,
  authUserGuard,
  expertSystemGuard,
  govGuard,
  mdaGuard,
} from '../shared/auth.guard';
import { SignupComponent } from '../pages/auth/signup/signup.component';
import { EmailVerificationNoticeComponent } from '../pages/auth/email-verification-notice/email-verification-notice.component';
import { EmailVerifiedComponent } from '../pages/auth/email-verified/email-verified.component';
import { ForgotPasswordComponent } from '../pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '../pages/auth/reset-password/reset-password.component';
import { ComingSoonComponent } from '../shared/widgets/coming-soon/coming-soon.component';
import { AgentFileUploadComponent } from '../pages/agent-file-upload/agent-file-upload.component';
import { AdminLayoutComponent } from '../pages/admin/admin.component';
import { PublicComponent } from '../pages/public/public.component';
import { ProtectedLayoutComponent } from '../shared/widgets/protected-layout/protected-layout.component';
import { GovernorLayoutComponent } from '../pages/governor_dashboard/governor.component';
import { ExpertSystemLayoutComponent } from '../pages/expert-system/expert-system.component';
import { ReceiptVerificationComponent } from '../pages/public/receipt-verification/receipt-verification.component';
import { AdminLoginComponent } from '../pages/admin/admin-login/admin-login.component';
import { SuperadminLoginComponent } from '../pages/admin/superadmin-login/superadmin-login.component';
import { TspLoginComponent } from '../pages/admin/tsp-login/tsp-login.component';
import { GovLoginComponent } from '../pages/admin/gov-login/gov-login.component';
import { PaymentRedirectComponent } from '../pages/paymentRedirect/payment-redirect/payment-redirect.component';
import { MdaLoginComponent } from '../pages/admin/mda-login/mda-login.component';
import { MdaLayoutComponent } from '../pages/mda/mda.component';
import { PaymentSummaryOpenComponent } from '../pages/paymentSummary/payment-summary-open/payment-summary-open.component';
import { MakePaymentTspComponent } from '../pages/paymentSummary/make-payment-tsp/make-payment-tsp.component';
import { PaymentSummaryAdminComponent } from '../pages/paymentSummary/payment-summary-admin/payment-summary-admin.component';
import { PaymentSummaryComponent } from '../pages/paymentSummary/payment-summary/payment-summary.component';
import { PaymentGatewaysTspComponent } from '../pages/paymentGateways/payment-gateways-tsp/payment-gateways-tsp.component';
import { PaymentRedirectOpenComponent } from '../pages/paymentRedirect/payment-redirect-open/payment-redirect-open.component';
import { AvailablePaymentGatewaysComponent } from '../pages/paymentGateways/available-payment-gateways/available-payment-gateways.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    component: PublicComponent,
    loadChildren: () =>
      import('../pages/public/public.route').then(
        (route) => route.PUBLIC_ROUTES
      ),
  },
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () =>
      import('../pages/auth/auth.route').then((route) => route.AUTH_ROUTES),
  },
  {
    path: 'user',
    component: ProtectedLayoutComponent,
    loadChildren: () =>
      import('../pages/user-protected/user-protected.route').then(
        (route) => route.USER_PROTECTED_ROUTES
      ),
    canActivate: [authUserGuard],
  },
  {
    path: 'signup',
    component: SignupComponent,
  },
  {
    path: 'admin-auth',
    component: AdminLoginComponent,
  },
  {
    path: 'superadmin',
    component: SuperadminLoginComponent,
  },
  {
    path: 'tsp',
    component: TspLoginComponent,
  },
  {
    path: 'governor',
    // path: 'gov',
    component: GovLoginComponent,
  },
  {
    path: 'MDAmanager',
    component: MdaLoginComponent,
  },

  // Receipts and Invoices pages
  {
    //For authenticated users
    path: 'payment_summary',
    component: PaymentSummaryComponent,
    canActivate: [authUserGuard],
  },
  {
    //For Unauthenticated users
    path: 'payment_summary_open',
    component: PaymentSummaryOpenComponent,
  },
  {
    //For TSPs
    path: 'make_payment_tsp',
    component: MakePaymentTspComponent,
  },
  {
    //For authenticated user type
    path: 'payment-gateways',
    component: AvailablePaymentGatewaysComponent,
    canActivate: [authUserGuard],
  },
  {
    //For Unauthenticated user type
    path: 'payment-gateways-open',
    component: AvailablePaymentGatewaysComponent,
  },
  {
    //For admin user type
    path: 'payment-gateways-admin',
    component: AvailablePaymentGatewaysComponent,
  },
  {
    //For TSPs
    path: 'payment-gateways-tsp',
    component: PaymentGatewaysTspComponent,
  },

  {
    path: 'payment-redirect',
    component: PaymentRedirectComponent,
    canActivate: [authUserGuard],
  },
  {
    path: 'payment-redirect-open',
    component: PaymentRedirectOpenComponent,
  },
  {
    path: 'email-notice',
    component: EmailVerificationNoticeComponent,
  },
  {
    path: 'verify_email',
    component: EmailVerifiedComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset_password',
    component: ResetPasswordComponent,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    canActivate: [authSuperAdminGuard],
  },
  {
    path: 'uploads',
    component: AgentFileUploadComponent,
    // canActivate: [authAgentGuard],
  },
  {
    path: 'receipt-verification',
    component: ReceiptVerificationComponent,
    // canActivate: [authAgentGuard],
  },
  {
    path: 'uploads',
    component: AgentFileUploadComponent,
    // canActivate: [authAgentGuard],
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    loadChildren: () =>
      import('../pages/admin/admin.route').then((route) => route.ADMIN_ROUTES),
    canActivate: [authAdminGuard],
  },
  {
    path: 'gov_dashboard',
    component: GovernorLayoutComponent,
    loadChildren: () =>
      import('../pages/governor_dashboard/governor.route').then(
        (route) => route.GOVERNOR_ROUTES
      ),
    canActivate: [govGuard],
  },
  {
    path: 'expert-system',
    component: ExpertSystemLayoutComponent,
    loadChildren: () =>
      import('../pages/expert-system/expert-system.route').then(
        (route) => route.EXPERT_SYSTEM_ROUTES
      ),
    canActivate: [expertSystemGuard],
  },
  {
    path: 'mda',
    component: MdaLayoutComponent,
    loadChildren: () =>
      import('../pages/mda/mda.route').then((route) => route.MDA_ROUTES),
    canActivate: [mdaGuard],
  },
  { path: '**', redirectTo: '' },
];

export class AppRoutingModule {}
