import { createReducer, on } from '@ngrx/store';
import { setStateAction } from '../actions/admin-dashboard.actions';
import { Action } from '../../models';
import dayjs from 'dayjs';

export interface AdminDashboardReducerInitialState {
  cards: any;
  cardsLoading: boolean;
  maxDate: string;
  minDate: string;
  esId: string;
  pcId: string;
  pgId: string;
  tpcId: string;
  mdaId: string;
  toId: string;
  engineCodeId: string;
}

export const initialState: AdminDashboardReducerInitialState = {
  cards: null,
  cardsLoading: false,
  maxDate: dayjs().format('YYYY-MM-DD'),
  minDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  esId: '',
  pcId: '',
  pgId: '',
  tpcId: '',
  mdaId: '',
  toId: '',
  engineCodeId: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  })
);

export function adminDashboardReducer(
  state: AdminDashboardReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
