<div [ngClass]="{ 'flex flex-col justify-center w-full h-dvh bg-img': true }">
  <header [ngClass]="{ 'pt-6 pl-6 bg-transparent fixed top-0 left-0': true }">
    <div
      [ngClass]="{
        'text-white underline': true
      }"
    >
      <a
        routerLink="/"
        [ngClass]="{
          'flex items-center gap-2 text-sm font-bold': true
        }"
      >
        <svg-icon
          src="assets/svgs/back_arrow_icon.svg"
          [svgStyle]="{ 'width.px': 16 }"
        ></svg-icon>
        Back to Homepage</a
      >
    </div>
  </header>
  <div
    [ngClass]="{
      '': true
    }"
    class="w-full max-w-[1205px] mx-auto flex flex-1 h-full max-h-[607px] justify-center rounded-2xl relative bg-[color:#F2F3F5] bg-[url('/assets/images/governor_image.png')] bg-no-repeat bg-[position:right_50%] bg-[size:400px_auto]"
  >
    <div
      [ngClass]="{
        'absolute top-0 left-0 h-full flex flex-col justify-center items-center': true
      }"
    >
      <img
        [ngSrc]="'assets/images/gov_seal_resized.png'"
        alt="gov-seal"
        priority
        width="250"
        height="242"
        [ngClass]="{ 'h-[242px] w-[250px]': true }"
      />
      <div [ngClass]="{ 'mt-4': true }">
        <img
          [ngSrc]="'assets/images/stars_img.png'"
          alt="gov-seal"
          priority
          width="66"
          height="20"
          [ngClass]="{ 'h-[20px] w-[66px]': true }"
        />
      </div>
      <div [ngClass]="{ 'px-4 text-[#29444D] text-xl pt-8': true }">
        <p [ngClass]="{ 'max-w-[352px] mx-auto text-center': true }">
          <strong>Paykaduna</strong> is an initiative of Gov. Uba Sani, aimed at
          improving the overall tax administration in the state.
        </p>
      </div>
    </div>
    <div
      [ngClass]="{
        'flex-1 flex items-center justify-center': true
      }"
    >
      <div
        class="w-full p-8 bg-white rounded-2xl max-w-[432px] mx-auto relative"
      >
        <div
          [ngClass]="{
            'flex flex-col items-center justify-center gap-4': true
          }"
        >
          <img
            [ngSrc]="'assets/login-assets/pay_kaduna_icon.png'"
            alt="primeguage-logo"
            priority
            width="62"
            height="52"
            [ngClass]="{ 'h-[52px] w-[62px]': true }"
          />
          <h3
            [ngClass]="{
              'text-[#292929] font-bold text-xl text-center flex flex-col items-center': true
            }"
          >
            <p>Governor's Portal</p>
            <p [ngClass]="{ 'text-base font-medium': true }">Sign in</p>
          </h3>
        </div>
        <div>
          <form
            [formGroup]="authForm"
            (ngSubmit)="onSubmit()"
            class="flex flex-col flex-1 w-full p-4"
          >
            <div class="flex flex-col gap-6 pb-4">
              <div class="w-full mx-auto">
                <label for="" class="flex flex-col">
                  <div class="relative flex w-full">
                    <!-- <input appGeneralInput type='email' placeholder="Enter email" formControlName="email" /> -->
                    <app-general-input
                      label="Email"
                      type="email"
                      placeholder="Governor's email"
                      controlName="email"
                      [formGroup]="authForm"
                    ></app-general-input>

                    <div
                      *ngIf="
                        !!authForm.get('email').errors &&
                        authForm.get('email').touched
                      "
                      class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                    >
                      <span *ngIf="authForm.get('email').errors?.email">{{
                        "Invalid email"
                      }}</span>
                      <span *ngIf="authForm.get('email').errors?.required"
                        >Field is required</span
                      >
                    </div>
                  </div>
                </label>
              </div>
              <div class="w-full mx-auto">
                <label for="" class="flex flex-col">
                  <div class="relative flex w-full">
                    <app-password-input
                      label="Password"
                      placeholder="Enter password"
                      controlName="password"
                      [formGroup]="authForm"
                    ></app-password-input>

                    <div
                      class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                      *ngIf="
                        !!authForm.get('password').errors &&
                        authForm.get('password').touched
                      "
                    >
                      <span *ngIf="authForm.get('password').errors?.required"
                        >Field is required</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="pt-8 mt-auto text-center">
              <button
                appGeneralButton
                type="submit"
                [disabled]="mutation.isPending()"
                [ngClass]="{
                  'disabled:opacity-50 !bg-[#178CA6] capitalize': true
                }"
              >
                {{
                  mutation.isPending()
                    ? "Logging in.\
                    Please\
                    wait..."
                    : "Login"
                }}
              </button>
            </div>
            <div>
              <p
                [ngClass]="{ 'text-xs text-[#29444D] text-center mt-5': true }"
              >
                Paykaduna... Setting the pace in Tax Administration..
              </p>
            </div>
            <!-- <div [ngClass]="{ 'text-center pt-4': true }">
              <a
                href=""
                class="text-sm leading-6 underline text-[#178CA6] underline-offset-[3px] text-center font-bold"
                [routerLink]="[]"
                routerLinkActive="bg-slate-200"
                [routerLinkActiveOptions]="{ exact: true }"
                [queryParams]="{ a: 'forgot-password' }"
                >Forgot password</a
              >
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
