<div [ngClass]="{ 'flex flex-col w-full h-dvh': true }">
  <header [ngClass]="{ 'pt-6 pl-6 bg-transparent fixed top-0 left-0': true }">
    <div class="flex items-center">
      <img
        [ngSrc]="'assets/login-assets/pay_kaduna_icon.png'"
        alt="primeguage-logo"
        priority
        width="62"
        height="52.5"
        [ngClass]="{ 'h-[52.5px] w-[62px]': true }"
      />
      <p
        [ngClass]="{
          'text-[#292929] text-[20px] leading-[150%] -tracking-[1px] font-semibold': true
        }"
      >
        PAYKADUNA
      </p>
    </div>
  </header>
  <div [ngClass]="{ 'h-svh w-full flex flex-1': true }">
    <div
      [ngClass]="{
        'flex-1 flex justify-center items-center px-11 background-gradient': true
      }"
    >
      <div
        [ngClass]="{
          'flex items-center justify-center py-6 bg-white w-full': true
        }"
      >
        <img
          [ngSrc]="'assets/login-assets/kd_logo.png'"
          alt="primeguage-logo"
          priority
          width="300"
          height="300"
          [ngClass]="{ 'h-[300px] w-[300px]': true }"
        />
      </div>
    </div>
    <div
      [ngClass]="{
        'flex-1 bg-[#F8F8F8] pl-9 pr-[54px] flex items-center justify-center': true
      }"
    >
      <div
        class="w-full p-8 bg-white rounded-2xl max-w-[554px] mx-auto relative"
      >
        <div
          [ngClass]="{
            'absolute bottom-[calc(100%+12px)] left-1/2 -translate-x-1/2': true
          }"
        >
          <a
            routerLink="/"
            [ngClass]="{
              'flex items-center gap-2 text-[#178CA6] text-sm font-bold': true
            }"
          >
            <svg-icon
              src="assets/svgs/back_arrow_icon.svg"
              [svgStyle]="{ 'width.px': 16 }"
            ></svg-icon>
            Back to Homepage</a
          >
        </div>
        <div
          [ngClass]="{
            'flex flex-col items-center justify-center gap-6': true
          }"
        >
          <img
            [ngSrc]="'assets/login-assets/pay_kaduna_icon.png'"
            alt="primeguage-logo"
            priority
            width="62"
            height="52.5"
            [ngClass]="{ 'h-[52.5px] w-[62px]': true }"
          />
          <h3
            [ngClass]="{
              'text-[#292929] font-bold text-2xl text-center': true
            }"
          >
            MDA Login
          </h3>
        </div>
        <div>
          <form
            [formGroup]="authForm"
            (ngSubmit)="onSubmit()"
            class="flex flex-col flex-1 w-full p-4"
          >
            <div class="flex flex-col gap-6 pb-4">
              <div class="w-full mx-auto">
                <label for="" class="flex flex-col">
                  <div class="relative flex w-full">
                    <!-- <input appGeneralInput type='email' placeholder="Enter email" formControlName="email" /> -->
                    <app-general-input
                      label="Email"
                      type="email"
                      placeholder="Enter email"
                      controlName="email"
                      [formGroup]="authForm"
                    ></app-general-input>

                    <div
                      *ngIf="
                        !!authForm.get('email').errors &&
                        authForm.get('email').touched
                      "
                      class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                    >
                      <span *ngIf="authForm.get('email').errors?.email">{{
                        "Invalid email"
                      }}</span>
                      <span *ngIf="authForm.get('email').errors?.required"
                        >Field is required</span
                      >
                    </div>
                  </div>
                </label>
              </div>
              <div class="w-full mx-auto">
                <label for="" class="flex flex-col">
                  <div class="relative flex w-full">
                    <app-password-input
                      label="Password"
                      placeholder="Enter password"
                      controlName="password"
                      [formGroup]="authForm"
                    ></app-password-input>

                    <div
                      class="absolute top-[calc(100%+1px)] text-[12px] pl-[5px] text-[#EB5757] flex items-center gap-[4px]"
                      *ngIf="
                        !!authForm.get('password').errors &&
                        authForm.get('password').touched
                      "
                    >
                      <span *ngIf="authForm.get('password').errors?.required"
                        >Field is required</span
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
            <div class="mt-auto text-center pt-[72px]">
              <button
                appGeneralButton
                type="submit"
                [disabled]="mutation.isPending()"
                [ngClass]="{
                  'disabled:opacity-50 !bg-[#178CA6] capitalize': true
                }"
              >
                {{
                  mutation.isPending()
                    ? "Logging in.\
                    Please\
                    wait..."
                    : "Login"
                }}
              </button>
            </div>
            <div [ngClass]="{ 'text-center pt-4': true }">
              <a
                href=""
                class="text-sm leading-6 underline text-[#178CA6] underline-offset-[3px] text-center font-bold"
                [routerLink]="[]"
                routerLinkActive="bg-slate-200"
                [routerLinkActiveOptions]="{ exact: true }"
                [queryParams]="{ a: 'forgot-password' }"
                >Forgot password</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
