import { CommonModule } from '@angular/common';
import { Component, computed, inject, Input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AuthService } from '../../../core/services/auth.service';

export const menuList = [
  {
    name: 'Dashboard',
    link: ['/', 'admin'],
    type: 'All',
    exact: true,
    icon: 'assets/svgs/menu/dashboard.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'Analytics',
    link: ['/', 'admin', 'analytics'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/analytic.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'Expert Systems Module',
    link: ['/', 'admin', 'expert-system-modules'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/expert-system.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'Reports',
    subMenuList: [
      {
        name: 'Invoice Report',
        link: ['/', 'admin', 'invoice-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/file-text.svg',
        isAvailable: ['SuperAdmin', 'admin'],
      },
      {
        name: 'Collection Report',
        link: ['/', 'admin', 'collection-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/hard-drive.svg',
        isAvailable: ['SuperAdmin', 'admin'],
      },
      {
        name: 'Settlement Report',
        link: ['/', 'admin', 'settlement-reports'],
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/report.svg',
        isAvailable: ['SuperAdmin', 'admin'],
      },
    ],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/report.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'MDAs',
    link: ['/', 'admin', 'mda-reports'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/expert-system.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'Tax Payers',
    link: ['/', 'admin', 'tax-payers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-payer.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Tax Managers',
    link: ['/', 'admin', 'tax-managers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-manager.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Enumerators',
    link: ['/', 'admin', 'enumerators'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-payer.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'eTCC',
    link: ['/', 'admin', 'etcc'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/etcc.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'POS Managers',
    link: ['/', 'admin', 'pos-managers'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/pos-manager.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Consult Connect',
    link: ['/', 'admin', 'consult-connect'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/consult-connect.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Audit Trail',
    link: ['/', 'admin', 'audit-trail'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/audit-trail.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Tax Support',
    link: ['/', 'admin', 'tax-support'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/tax-support.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Profile',
    link: ['/', 'admin', 'profile'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/profile.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
  {
    name: 'CMS',
    link: ['/', 'admin', 'cms'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/cms.svg',
    isAvailable: ['SuperAdmin'],
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
    isAvailable: ['SuperAdmin', 'admin'],
  },
];

@Component({
  selector: 'app-admin-sidemenu',
  standalone: true,
  imports: [
    SvgIconComponent,
    NzToolTipModule,
    RouterModule,
    NgScrollbarModule,
    CommonModule,
    NzMenuModule,
  ],
  templateUrl: './admin-sidemenu.component.html',
  styleUrl: './admin-sidemenu.component.css',
})
export class AdminSidemenuComponent {
  authService = inject(AuthService);
  @Input({ required: true }) isCollapsed = false;

  isSuperAdmin = signal<boolean>(false);
  menuList = computed(() => {
    const isSuperAdmin = this.isSuperAdmin();

    return menuList.filter((menu) => {
      if (isSuperAdmin) {
        return true;
      }

      return menu.isAvailable.includes('admin');
    });
  });

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    const userData = this.authService.userData();
    const role = userData?.roles?.[0];
    this.isSuperAdmin.set(role === 'SuperAdmin');
  }
}
