import { createReducer, on } from '@ngrx/store';
import {
  resetInvoiceStore,
  setInvoiceModalStep,
  setStateAction,
  triggerInvoiceDownload,
} from '../actions/invoice.actions';
import { Action } from '../../models';
import { ObjectLiteral } from '../../models/ObjectLiteral';

export interface InvoiceReducerInitialState {
  modalState: boolean;
  triggerDownload: boolean;
  step: number;
  mda: ObjectLiteral | null;
  mdaUserInvoiceInfo: ObjectLiteral | null;
  userTpuiFromMda: string;
}

export const initialState: InvoiceReducerInitialState = {
  modalState: false,
  //   modalState: true,
  triggerDownload: false,
  step: 0,
  //   step: 6,
  mda: null,
  mdaUserInvoiceInfo: null,
  userTpuiFromMda: '',
};

const reducer = createReducer(
  initialState,
  on(setStateAction, (state, payload) => {
    return {
      ...state,
      ...payload,
    };
  }),
  on(triggerInvoiceDownload, (state, payload) => {
    return {
      ...state,
      triggerDownload: payload.data,
    };
  }),
  on(setInvoiceModalStep, (state, payload) => {
    return {
      ...state,
      step:
        payload.event === 'increment'
          ? state.step + payload.data
          : payload.event === 'decrement'
          ? state.step - payload.data
          : payload.data,
      ...(!!payload.mdaUserInvoiceInfo
        ? { mdaUserInvoiceInfo: payload.mdaUserInvoiceInfo }
        : {}),
      ...(!!payload.mda ? { mda: payload.mda } : {}),
    };
  }),
  on(resetInvoiceStore, () => {
    return initialState;
  })
);

export function invoiceReducer(
  state: InvoiceReducerInitialState,
  action: Action
) {
  return reducer(state, action);
}
