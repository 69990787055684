import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'app-register-ui',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './register-ui.component.html',
  styleUrl: './register-ui.component.css',
  host: {
    class: 'block',
  },
})
export class RegisterUiComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  @Input({ required: true }) accounTypeList;
  goToRegistrationForm(id: string | number) {
    this.router.navigate([`/auth/register/${id}`]);
  }
  switchToLogin() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        a: 'login',
      },
    });
  }
  goBack() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: null,
    });
  }
}
