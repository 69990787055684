import { CommonModule, Location } from '@angular/common';
import {
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { AuthService } from '../../../core/services/auth.service';
import { OutsideClickDirective } from '../../directives/outside-click.directive';
import { LocalService } from '../../../core/services/local.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzInputModule } from 'ng-zorro-antd/input';
import dayjs from 'dayjs';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../core/store';
import { setStateAction } from '../../../core/store/actions/settings.actions';
import { Subscription } from 'rxjs';
import { getSettingsStore } from '../../../core/store/selectors/settings.selectors';

@Component({
  selector: 'app-admin-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    NzAvatarModule,
    NzDropDownModule,
    NzInputModule,
    NzButtonModule,
  ],
  templateUrl: './admin-header.component.html',
  styleUrl: './admin-header.component.css',
})
export class AdminHeaderComponent implements OnInit, OnDestroy {
  store = inject(Store<AppState>);
  localService = inject(LocalService);
  route = inject(ActivatedRoute);
  location = inject(Location);
  authService = inject(AuthService);
  router = inject(Router);

  settingsStoreSub: Subscription;

  @Input({ required: true }) slug: string;

  showPopover = false;
  sideMenuCollapsedState = false;
  menuList = signal([]);

  get header() {
    const pathname = this.location.path();
    switch (pathname) {
      case '/sadmin':
        return 'Dashboard';
      case '/sadmin/settings':
        return 'Settings';

      default:
        return 'Dashboard';
    }
  }

  get userData() {
    return this.authService.userData();
  }
  get userRole() {
    return !!this.authService.userData()?.roles &&
      Array.isArray(this.authService.userData()?.roles)
      ? this.authService.userData()?.roles?.[0]
      : 'Admin';
  }

  get abbr() {
    const firstName = (this.userData?.firstName as string) || '';
    const lastName = (this.userData?.lastName as string) || '';
    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`;
  }

  get todayDate() {
    return dayjs().format('DD/MM/YYYY');
  }

  toggleSideMenuCollapsedState() {
    this.store.dispatch(
      setStateAction({ sideMenuCollapsedState: !this.sideMenuCollapsedState })
    );
  }

  clickStatusHandler(status: any) {
    if (!status) {
      this.showPopover = false;
    }
  }

  popoverHandler() {
    this.showPopover = !this.showPopover;
  }

  //   logoutHandler() {
  //     this.localService.clearData();
  //     this.router.navigateByUrl('/', { replaceUrl: true });
  //   }
  logoutHandler(name: string, path: string) {
    this.router.navigateByUrl(path, {
      replaceUrl: true,
    });
    if (name === 'Log Out') {
      const role = this.authService.userData()?.roles?.[0];
      this.authService.clearUserData();
      switch (role) {
        case 'Governor':
          this.router.navigate(['/governor']);
          break;
        case 'SuperAdmin':
          this.router.navigate(['/superadmin']);
          break;
        case 'Admin':
        case 'Administrator':
          this.router.navigate(['/admin-auth']);
          break;
        case 'Expert System':
          this.router.navigate(['/tsp']);
          break;
        case 'MDA User':
          this.router.navigate(['/MDAmanager']);
          break;

        default:
          this.router.navigate(['/']);
          break;
      }
    }
  }

  ngOnInit(): void {
    this.settingsStoreSub = this.store
      .pipe(select(getSettingsStore))
      .subscribe((settingsStore) => {
        this.sideMenuCollapsedState = settingsStore.sideMenuCollapsedState;
      });

    this.menuList.set([
      {
        name: 'Settings',
        link: `/${this.slug}/settings`,
        type: 'All',
        exact: false,
        icon: 'assets/navMenu/paye-manager-icon.svg',
      },
      {
        name: 'Service Logs',
        link: `/${this.slug}/logs`,
        type: 'All',
        exact: false,
        icon: 'assets/svgs/menu/log-out.svg',
      },
      {
        name: 'Log Out',
        link: '/',
        type: 'Danger',
        exact: true,
        icon: 'assets/svgs/menu/log-out.svg',
      },
    ]);
  }

  ngOnDestroy(): void {
    if (this.settingsStoreSub) this.settingsStoreSub.unsubscribe();
  }
}
